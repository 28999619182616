import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Container/ContentContainer"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import OnClickButtonGrey from "../Core/Buttons/OnClickButtonGrey"
import { Link } from "gatsby"
import CircleImage from "../../resources/img/core/usp/circle.svg"

const TextImageBlockContainer = styled.div`
  margin: 95px auto 106px;

  @media (min-width: ${breakpoints.md}) {
    margin: 119px auto;
  }
`

const Content = styled.div`
  text-align: center;
  h1 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 1;
    margin-bottom: 34px;
  }

  p {
    ${fonts.labGrotesqueLight};
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 28px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    padding-right: 30px;
  }
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    border-radius: 50px;
  }
`

const InputContainer = styled.div`
  margin-top: 36px;
  border: 1px solid ${colors.grey};
  input {
    ${fonts.labGrotesqueLight};
    font-size: 18px;
    line-height: 1.6;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 14px 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 64px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 15px;

  button {
    width: 100%;
  }
`

const ContentCol = styled(Col)`
  margin-bottom: 85px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    ${(props) => (props.left ? "padding-right: 40px" : "padding-left: 40px")};
  }
  @media (min-width: ${breakpoints.lg}) {
    ${(props) => (props.left ? "padding-right: 80px" : "padding-left: 80px")};
  }
  @media (min-width: ${breakpoints.xl}) {
    ${(props) => (props.left ? "padding-right: 120px" : "padding-left: 120px")};
  }
  @media (min-width: ${breakpoints.xxl}) {
    ${(props) => (props.left ? "padding-right: 160px" : "padding-left: 160px")};
  }
`

const StyledRow = styled(Row)`
  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 110px 0 66px;
  }
`

const ImageCol = styled(Col)`
  position: relative;
`

const Links = styled.div`
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const LinkContainer = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  cursor: pointer;
  a {
    color: ${colors.grey};
    text-decoration: none;
  }
`

const ImageOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 49px;
  }
`

const OverlayText = styled.div`
  ${fonts.canelaThin};
  color: ${colors.white};
  max-width: 300px;
  font-size: 32px;
  line-height: 1;
  text-align: center;

  i, em {
    font-style: italic;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
  }
`

const TextImageBlock = (props) => {
  const [ email, setEmail ] = useState("")
  const { content, image, imagePosition, imageText } = props

  return (
    <TextImageBlockContainer>
      <ContentContainer>
        <StyledRow>
          <ContentCol md={6} left={!imagePosition ? 1 : 0} alignSelf="center">
            <Content>{renderRichText(content)}</Content>
            <InputContainer>
              <input
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputContainer>
            <ButtonContainer>
              <OnClickButtonGrey onClick={() => alert("Share")}>
                Start Sharing
              </OnClickButtonGrey>
              <Links>
                <LinkContainer>
                  <Link to={"/terms-of-use"}>Terms</Link>
                </LinkContainer>
                <LinkContainer>
                  <a
                    href={"https://www.friendbuy.com/terms-of-service"}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    friendbuy
                  </a>
                </LinkContainer>
              </Links>
            </ButtonContainer>
          </ContentCol>
          <ImageCol md={6} mdOrder={imagePosition ? "first" : "last"}>
            <StyledImage image={image.gatsbyImageData} alt={image.title} />
            {imageText && imageText.childMarkdownRemark.html !== "" && (
              <>
                <ImageOverlay>
                  <img src={CircleImage} alt="Circle" />
                </ImageOverlay>
                <ImageOverlay>
                  <OverlayText
                    dangerouslySetInnerHTML={{
                      __html: imageText.childMarkdownRemark.html,
                    }}
                  />
                </ImageOverlay>
              </>
            )}
          </ImageCol>
        </StyledRow>
      </ContentContainer>
    </TextImageBlockContainer>
  )
}

export default TextImageBlock
