import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Refer/PageContents"

const ReferPage = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page } = data
  const { metaTitle, metaDescription } = page

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <PageContents blocks={page.blocks} />
    </Layout>
  )
}

export default ReferPage

export const pageQuery = graphql`
  query ReferPageQuery {
    page: contentfulPage(slug: { eq: "refer-a-friend" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulTextImageBlock {
          id
          content {
            raw
          }
          imagePosition
          imageText {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            gatsbyImageData(width: 1356)
          }
        }
      }
    }
  }
`
