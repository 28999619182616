import React from "react"
import TextImageBlock from "./TextImageBlock"

const PageContents = (props) => {
  const { blocks } = props;
  const pageBlocks = []

  blocks.forEach((block, index) => {
    const {__typename: type} = block;

    if (type === "ContentfulTextImageBlock") {
      pageBlocks.push(<TextImageBlock {...block} key={index} />)
    }
  })
  return (
    <>
      {pageBlocks}
    </>
  )
}

export default PageContents
